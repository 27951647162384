@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl font-light;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-2xl;
  }
}

@layer components {
  .btn {
    @apply inline-flex select-none items-center justify-center;
    @apply rounded-full px-8 py-2;
    @apply text-sm font-medium;
    @apply shadow-md transition;
    @apply bg-primary hover:bg-darkGray text-white;
  }
}
/* The css below makes the prev/next arrows fully white in the react-responsive-carousel Carousel */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

/* The css below makes the carousel adjust it's height to reflect the flexbox, so that when you set { height: 0, min-height: 100% } to an image in the carousel, it fits its parent flexbox */
.carousel-root,
.carousel-root .carousel,
.carousel-root .carousel .slider-wrapper,
.carousel-root .carousel .slider-wrapper .slider,
.carousel-root .carousel .slider-wrapper .slider .slide {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.main {
  min-height: 100vh;
  @apply px-8 md:px-16;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin-top: 0.2em;
  margin-bottom: 0.4em;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
